<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>采集</el-breadcrumb-item>
      <el-breadcrumb-item>采集列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="20">
        <el-col :span="7">
          <el-input clearable placeholder=" 请输入内容" v-model="queryinfo.order_no">
            <el-button slot="append" icon="el-icon-search" @click="searchChange()"></el-button>
          </el-input>
        </el-col>
      </el-row>
      <el-table :data="userlist" border stripe>
        <!-- <el-table-column label="素材ID" prop="id"></el-table-column> -->
        <!-- <el-table-column label="审核员ID" prop="checker_id"></el-table-column> -->
        <!-- <el-table-column label="用户ID" prop="author_id"></el-table-column> -->
        <el-table-column label="采集名称" prop="drawing_name"></el-table-column>
        <el-table-column label="采集的网址">
          <template slot-scope="scope">
            <el-image style="height: 45px;" :src="scope.row.drawing_url" :preview-src-list="[scope.row.drawing_url]"
              fit="fill"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="上传时间" prop="create_time"></el-table-column>
        <el-table-column label="浏览量" prop="view_count"></el-table-column>
        <el-table-column label="采集量" prop="collect_count"></el-table-column>
        <el-table-column label="画板名称" prop="drawing_board.drawing_board_name"></el-table-column>
        <el-table-column label="画板分类" prop="drawing_board_category.category_name"></el-table-column>
        <el-table-column label="作品作者" prop="author.nickname">

        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="openLU(scope.row.drawing_id)">录入</el-button>
            <el-button type="primary" icon="el-icon-delete" size="mini"
              @click="deleteEditDialog(scope.row.drawing_id)"></el-button>

          </template>
        </el-table-column>
      </el-table>
      <!--分页区域-->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryinfo.page" :page-sizes="[5, 10, 15, 20]" :page-size="queryinfo.list_rows"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </el-card>
    <!-- 修改文章信息 -->
    <el-dialog title="查看素材信息" :visible.sync="editDialogVisible" width="50%" @closed="editDialogClosed">
      <el-form :model="editForm" :rules="editRules" ref="editFormRef" label-width="70px">
        <el-form-item label="素材id" prop="id">
          <el-input v-model="editForm.id" disabled></el-input>
        </el-form-item>
        <el-form-item label="审核" prop="create_time">
          <el-select v-model="editForm.check_status" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审核结果的理由" prop="id">
          <el-input v-model="editForm.check_reason"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editRoles">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="录入" :visible.sync="visible" width="600px">
      <el-form :model="editForm" :rules="editRules" ref="editFormRef" label-width="90px">
        <el-form-item label="录入分类:" prop="id">
          <el-select v-model="params.welcome_word_id" placeholder="请选择">

            <el-option v-for="item in welcomeList" :key="item.welcome_word_id" :label="item.category_name"
              :value="item.welcome_word_id">
            </el-option>
          </el-select>
        </el-form-item>


      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="inputCollection">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 获取文章信息列表
      queryinfo: {
        order_no: "",
        page: 1,
        list_rows: 5,
      },
      userlist: [],
      total: 0,
      //控制创建文章对话框展示与隐藏
      addDialogVisible: false,
      addForm: {
        title: "",
        editor: "",
        body: "",
        // 上传的图片临时路径
        pics: [],
      },
      addRules: {
        title: [
          {
            required: true,
            message: "请输入文章标题",
            trigger: "blur",
          },
        ],
        editor: [
          {
            required: true,
            message: "请输入作者名称",
            trigger: "blur",
          },
        ],
        body: [
          {
            required: true,
            message: "请输入文本内容",
            trigger: "blur",
          },
        ],
      },
      editDialogVisible: false,
      options: [
        {
          value: "2",
          label: "审核通过",
        },
        {
          value: "3",
          label: "驳回",
        },
      ],
      editForm: {},
      editRules: {
        title: [
          {
            required: true,
            message: "请输入文章标题",
            trigger: "blur",
          },
        ],
        editor: [
          {
            required: true,
            message: "请输入作者名称",
            trigger: "blur",
          },
        ],
        source: [
          {
            required: true,
            message: "请输入文章来源",
            trigger: "blur",
          },
        ],
        body: [
          {
            required: true,
            message: "请输入文本内容",
            trigger: "blur",
          },
        ],
      },
      // 上传图片的url
      uploadUrl: "http://127.0.0.1:8887/api/upload",
      // 图片上传组件的headers请求头对象
      headerObj: {
        Authorization: window.sessionStorage.getItem("token"),
      },
      previewPath: "",
      previewVisible: false,
      visible: false,
      welcomeList: [],
      welcome_word_id: '',
      drawing_id: '',
      params: {
        welcome_word_id: "",
        drawing_id: "",
        jump_url: "",
        sort: ""
      }
    };
  },
  created() {
    this.getinforList();
    this.reqWelcomeWord()
  },
  methods: {
    async reqWelcomeWord() {
      const { data: res } = await this.$http.post("welcomeWord/list")
      this.welcomeList = res.data

    },
    // 获取所有菜单
    async getinforList() {
      const { data: res } = await this.$http.post("drawing/index", {
        // params: this.queryinfo,
        drawing_name: this.queryinfo.order_no,
        page: this.queryinfo.page,
        list_rows: this.queryinfo.list_rows,
      });

      if (res.code !== 0) return this.$message.err("获取采集列表失败!");
      this.userlist = res.data.data;
      this.total = res.data.total;
    },
    // 监听handleSizeChange方法
    handleSizeChange(newsize) {
      this.queryinfo.list_rows = newsize;
      this.getinforList();
    },
    // 监听handleCurrentChange方法
    handleCurrentChange(newpage) {
      this.queryinfo.page = newpage;
      this.getinforList();
    },
    // 搜索方法
    searchChange() {
      this.queryinfo.page = 1;
      this.getinforList();
    },
    // 添加用户关闭
    addDialogClosed() {
      this.$refs["addFormRef"].resetFields();
    },
    //删除用户
    async deleteEditDialog(id) {
      // console.log(id);
      const confirmRessult = await this.$confirm(
        "此操作将永久删除, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);

      if (confirmRessult !== "confirm") {
        return this.$message.info("已经取消删除!");
      }
      const { data: res } = await this.$http.post("drawing/delete", {
        drawing_id: id,
      });
      if (res.code !== 0) {
        return this.$message.error("删除失败!");
      }
      console.log(res);
      this.$message.success("删除成功!");
      this.getinforList();
    },
    // 点击查看订单信息
    async showEditDialog(id) {
      console.log(10086, id);
      this.editDialogVisible = true;
      const { data: res } = await this.$http.post("material/detail", {
        id: id,
      });
      if (res.code !== 0) return this.$message.error("查询失败");

      this.editForm = res.data;
      if (this.editForm.check_status == 2) {
        this.editForm.check_status = "审核通过";
      } else if (this.editForm.check_status == 3) {
        this.editForm.check_status = "驳回";
      }
    },
    editDialogClosed() {
      this.$refs["editFormRef"].resetFields();
    },
    editUser() {
      // 预验证
      this.$refs.editFormRef.validate(async (valid) => {
        if (!valid) return;
        // 调用后台数据创建用户
        const { data: res } = await this.$http.post(
          "updateWikis/" + this.editForm.id,
          this.editForm
        );
        if (res.code !== 0) return this.$message.error(res.meta.msg);
        this.$message.success("编辑成功");
        //    关闭添加窗口
        this.editDialogVisible = false;
        this.getinforList();
      });
    },
    // 审核确认按钮
    editRoles() {
      this.$refs.editFormRef.validate(async (valid) => {
        if (!valid) return;
        const { data: res } = await this.$http.post(`material/check`, {
          id: this.editForm.id,
          check_status: this.editForm.check_status,
          check_reason: this.editForm.check_reason,
        });
        if (res.code !== 0) return this.$message.error("修改审核状态失败!");
        this.$message.success("修改审核状态成功!");
        this.getinforList();
        this.editDialogVisible = false;
      });
    },
    //修改使用状态
    async modifyUseStatus(id, status) {
      const { data: res } = await this.$http.post("drawing/modifyUseStatus", {
        id: id,
        use_status: status,
      });
      this.$message.success(res.message);
      //   this.getinforList();
    },
    openLU(id) {
      this.visible = true
      this.params.welcome_word_id = ""
      this.params.drawing_id = id
    },
    async inputCollection() {
      const { data: res } = await this.$http.post("DrawingBoardAd/add", this.params)
      if (res.code == 0) {
        this.$message.success(res.message)
      } else {
        this.$message.error('美图录入失败')
      }
      this.visible = false
    }
  },
};
</script>

<style lang="less" scoped>
.el-image {
  width: 100px;
  height: 160px;
  object-fit: cover;
}
</style>