<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">

            <el-breadcrumb-item>用户管理</el-breadcrumb-item>
            <el-breadcrumb-item>角色列表</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <el-row>
                <el-col>
                    <el-button type="primary" @click="addDialogVisible = true">添加角色</el-button>
                </el-col>
            </el-row>
            <!-- 角色列表区域 -->
            <el-table :data="rolelist" border stripe>
                <!-- <el-table-column type="expand">
                    <template slot-scope="scope">
                        <el-row :class="['bdbottom',i1===0?'bdtop':'','vcenter']" v-for="(item1,i1) in scope.row.children" :key="item1.id">
                            <el-col :span="5">
                                <el-tag  closable @close="removeRightById(scope.row,item1.id)">{{item1.authName}}</el-tag>
                                <i class="el-icon-caret-right"></i>
                            </el-col>
                            <el-col :span="19">
                                <el-row :class="[i2===0?'':'bdtop','vcenter']" v-for="(item2,i2) in item1.children" :key="item2.id">
                                    <el-col :span='6'>
                                        <el-tag type='success'  closable @close="removeRightById(scope.row,item2.id)">{{item2.authName}}</el-tag>
                                        <i class="el-icon-caret-right"></i>
                                    </el-col>
                                    <el-col :span='18'>
                                        <el-tag type='warning' v-for="(item3) in item2.children" :key="item3.id" closable @close="removeRightById(scope.row,item3.id)">{{item3.authName}}</el-tag>

                                    </el-col>
                                </el-row>
                            </el-col>
                        </el-row>
                    </template>
</el-table-column> -->
                <!-- 索引列 -->

                <el-table-column label="角色ID" prop='admin_role_id'></el-table-column>
                <el-table-column label="角色名称" prop='role_name'></el-table-column>
                <el-table-column label="创建时间" prop='create_time'></el-table-column>
                <el-table-column label="操作" width='300px'>
                    <template slot-scope="scope">
                        <el-button size='mini' type="primary" icon="el-icon-edit"
                            @click="showEditDialog(scope.row.admin_role_id)">编辑</el-button>
                        <el-button size='mini' type="danger" icon="el-icon-delete"
                            @click="removeRoles(scope.row)">删除</el-button>
                        <!-- <el-button size='mini' type="warning" icon="el-icon-setting">分配权限</el-button> -->
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="queryinfo.page" :page-sizes="[5, 10, 15, 20]" :page-size="queryinfo.list_rows"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </el-card>
        <el-dialog title="添加角色" :visible.sync="addDialogVisible" width="50%" @close="addDialogClosed">
            <el-form ref="addFormRef" :model="addForm" :rules="addFormRules" label-width="100px">
                <el-form-item label="角色名称" prop="roleName">
                    <el-input v-model="addForm.role_name"></el-input>
                </el-form-item>
                <el-form-item label="角色描述" prop="admin_menu_ids">
                    <el-tree :data="menuList" show-checkbox node-key="admin_menu_id" :props="defaultProps" ref="tree">
                    </el-tree>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="addRoles()">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog title="修改角色数据" :visible.sync="editDialogVisible" width="50%" @close="editDialogClosed">
            <el-form ref="editFormRef" :model="editForm" :rules="editFormRules" label-width="100px">
                <el-form-item label="角色名称">
                    <el-input v-model="editForm.role_name"></el-input>
                </el-form-item>
                <el-form-item label="角色描述">

                    <el-tree :data="menuList" show-checkbox node-key="admin_menu_id" :default-expanded-keys="[]"
                        :default-checked-keys="editForm.admin_menu_ids" :props="defaultProps" ref="modifyTree">
                    </el-tree>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="editRoles()">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            rolelist: [],
            addDialogVisible: false,
            addForm: {
                role_name: '',
                admin_menu_ids: []
            },
            addFormRules: {
                role_name: [{
                    required: true,
                    message: '请输入参数名称',
                    trigger: 'blur'
                }],

            },
            total: 0,
            editDialogVisible: false,
            editForm: {},
            editFormRules: {
                role_name: [{
                    required: true,
                    message: '请输入参数名称',
                    trigger: 'blur'
                }],

            },
            //分页
            queryinfo: {
                role_name: '',
                page: 1,
                list_rows: 5,
            },
            defaultProps: {
                children: 'children',
                label: 'menu_name'
            },
            menuList: []
        }
    },
    created() {
        this.getRolesList()
        this.addRole()
    },
    methods: {
        // 获取所有菜单
        async getRolesList() {
            const {
                data: res
            } = await this.$http.post('adminRole/index', this.queryinfo)
            // if (res.meta.status !== 200) return this.$message.error("获取角色列表失败!")
            console.log(res.data.data)
            this.rolelist = res.data.data
            this.total = res.data.total
            // console.log(this.rolelist);
        },
        async removeRightById(role, rightId) {
            const confirmResult = await this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(err => err)
            if (confirmResult !== "confirm") {
                return this.$message.info('取消了删除!')
            }
            // console.log(222);
            const {
                data: res
            } = await this.$http.delete(`roles/${role.id}/rights/${rightId}`)
            // console.log('111', res);
            if (res.meta.status !== 200) return this.$message.error("删除权限失败!")

            role.children = res.data
            this.$message.success('删除权限成功!')
        },
        addDialogClosed() {
            this.$refs.addFormRef.resetFields()
        },
        addRoles() {
            console.log(this.addForm)
            this.addForm.admin_menu_ids = this.$refs.tree.getCheckedKeys()
            this.$refs.addFormRef.validate(async valid => {
                if (!valid) return
                const {
                    data: res
                } = await this.$http.post(`adminRole/add`, {
                    role_name: this.addForm.role_name,
                    admin_menu_ids: this.addForm.admin_menu_ids
                })
                if (res.code !== 0) return this.$message.error(res.msg)
                this.$message.success("添加角色成功!")
                this.getRolesList()
                this.addForm.admin_menu_ids = []
                this.addForm.role_name = ''
                this.$refs.tree.setCheckedKeys([])
                this.addDialogVisible = false
            })
        },
        async removeRoles(row) {
            const confirmResult = await this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(err => err)
            // console.log(row);
            if (confirmResult !== 'confirm') return this.$message.info("已经取消删除！")
            const {
                data: res
            } = await this.$http.post(`adminRole/delete`, { admin_role_id: row.admin_role_id })
            if (res.code !== 0) return this.$message.error(res.message)
            this.$message.success("删除参数成功!")
            this.getRolesList()
        },
        async showEditDialog(id) {
            const {
                data: res
            } = await this.$http.post('adminRole/detail', { admin_role_id: id })
            if (res.code !== 0) {
                return this.$message.error("查询用户信息失败")
            }
            this.editForm = res.data
            console.log(this.editForm);
            this.editDialogVisible = true
            this.$nextTick(() => {
                this.$refs.modifyTree.setCheckedKeys(this.editForm.admin_menu_ids)
            })
        },
        editDialogClosed() {
            this.$refs.editFormRef.resetFields()
        },
        editRoles() {
            this.editForm.admin_menu_ids = this.$refs.modifyTree.getCheckedKeys()
            this.$refs.editFormRef.validate(async valid => {
                if (!valid) return
                const {
                    data: res
                } = await this.$http.put(`adminRole/update`, {
                    admin_role_id: this.editForm.admin_role_id,
                    role_name: this.editForm.role_name,
                    admin_menu_ids: this.editForm.admin_menu_ids
                })
                if (res.code !== 0) return this.$message.error("修改角色信息失败!")
                this.$message.success("修改角色信息成功!")
                this.getRolesList()
                this.editDialogVisible = false
            })
        },
        handleSizeChange(newsize) {
            this.queryinfo.list_rows = newsize
            this.getRolesList()
        },
        handleCurrentChange(newpage) {
            this.queryinfo.page = newpage
            this.getRolesList()
        },
        async addRole() {
            const { data: res } = await this.$http.post("adminMenu/family")
            this.menuList = res.data
        }
    },
}
</script>

<style lang="less" scoped>
.el-tag {
    margin: 7px;
}

.bdtop {
    border-top: 1px solid #eee
}

.bdbottom {
    border-top: 1px solid #eee
}

.vcenter {
    display: flex;
    align-items: center
}
</style>