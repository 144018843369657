import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../components/Login.vue";
import Home from "../components/home.vue";
// import Welcome from '../components/Welcome.vue'
// 菜单管理
import admin from "../components/users/menu.vue"; //菜单
import administrator from "../components/users/administrator.vue"; //管理员
import region from "../components/users/region.vue"; //地区
import occupation from "../components/users/occupation.vue"; //职业
import userlist from "../components/member/userlist.vue"; //用户列表

// // 信息管理
import wikipedia from "../components/wikipedia/wikipedia.vue";

// // 资讯管理
import pictures from "../components/information/pictures.vue";

// // 权限管理
import role from "../components/rights/role.vue";

//会员管理
import vipManage from "../views/vip/vipManage.vue";
import vipOrder from "../views/vip/vipOrder.vue"; //素材会员订单列表
import certificationList from "../components/member/certificationList.vue"; //用户实名认证审核列表
// 提现记录列表
import withdrawal from "../views/vip/withdrawal.vue";
// 采集
import collectTabulation from "../views/collect/collectTabulation.vue"; //采集列表
// 画板
import boardTabulation from "../views/board/boardTabulation.vue"; //画板列表
import drawingBoardClassify from "../views/board/drawingBoardClassify";
//素材
import materialTabulation from "../views/material/materialTabulation.vue"; //素材列表
import materialClassify from "../views/material/materialClassify"; //素材分类
//用户
import tubiExchange from "../components/users/tubiExchange.vue"; //图币兑换列表
import rotationChart from "../components/rotation/rotationChart.vue"; //图币兑换列表
import career from "../views/career";
import accd from "../components/users/accd.vue";

//友情链接
import linkList from "../views/friendshipLink/linkList";
import classify from "../views/friendshipLink/classify";

//行业咨询
import industryInformation from "../views/industryInformation";

//热门问题
import hotTopics from "../views/hotTopics";

//公告
import notice from "../views/notice";
// 热搜词
// import hotSearchWord from "../views/hotSearchWord";
//欢迎词
import greet from "../views/greet";
//通告
import notification from "../views/notification";

Vue.use(VueRouter);

const routes = [
  { path: "/", redirect: "/login" },
  { path: "/login", component: Login },
  {
    path: "/home",
    component: Home,
    children: [
      {
        path: "/admin",
        component: admin,
      },

      {
        path: "/wikipedia",
        component: wikipedia,
      },
      {
        path: "/industryInformation",
        component: industryInformation,
        name: "industryInformation",
      },

      {
        path: "/hotTopics",
        component: hotTopics,
        name: "hotTopics",
      },
      { path: "/region", component: region }, //地区
      { path: "/occupation", component: occupation }, //职业
      // { path: '/userlist', component: userlist }, //用户列表
      // { path: '/wikipedia', component: wikipedia },
      // { path: '/information', component: information },
      // { path: '/pictures', component: pictures },
      // { path: '/role', component: role },
      // { path: '/vipManage', component: vipManage },
      { path: "/withdrawal", component: withdrawal },
      { path: "/tubiExchange", component: tubiExchange }, //图币兑换列表
      // { path: '/collectTabulation', component: collectTabulation }, //采集列表
      { path: "/rotationChart", component: rotationChart }, //采集列表
      // { path: '/certificationList', component: certificationList }, //采集列表
      { path: "/notice", component: notice },
      { path: "/greet", component: greet },
      { path: "/notification", component: notification },
      {
        path: "/homeAds",
        component: () => import("@/views/HomeAds"),
        name: "homeAds",
      },
      // 分享币兑换
      {
        path: "/shareRecords/shareCoinExchange",
        component: () => import("@/views/shareRecords/shareCoinExchange"),
        name: "shareCoinExchange",
      },
      // 分享用户查询
      {
        path: "/shareRecords/shareUserQueries",
        component: () => import("@/views/shareRecords/shareUserQueries"),
        name: "shareUserQueries",
      },
    ],
  },
  {
    path: "/user",
    redirect: "/user/administrator",
    component: Home,
    children: [
      {
        path: "/user/administrator",
        component: administrator,
      },
      {
        path: "/user/userlist",
        component: userlist,
      },

      {
        path: "/user/role",
        component: role,
      },
      {
        path: "/user/certificationList",
        component: certificationList,
      },
      {
        path: "/user/career",
        component: career,
      },
      {
        path: "/user/accd",
        component: accd,
      },
    ],
  },
  {
    path: "/withdrawCashLog",
    component: Home,
    redirect: "/withdrawCashLog/withdrawalBalance",
    children: [
      {
        path: "/withdrawCashLog/withdrawalBalance",
        component: () => import("../views/withdrawCashLog/withdrawalBalance"),
      },
      {
        path: "/withdrawCashLog/shareCoinWithdrawal",
        component: () => import("../views/withdrawCashLog/shareCoinWithdrawal"),
      },
    ],
  },
  {
    path: "/material",
    redirect: "/material/materialTabulation",
    component: Home,
    children: [
      {
        path: "/material/materialTabulation",
        component: materialTabulation,
      },
      {
        path: "/material/materialClassify",
        component: materialClassify,
      },
      {
        path: "/material/materialOrder",
        component: () => import("@/views/materialOrder"),
      }, //素材订单
      {
        path: "/material/materialReport",
        component: () => import("@/views/material/materialReport.vue"),
      },
      {
        path: "/material/softwareType",
        component: () => import("../views/software/softwareType"),
      },
      {
        path: "/material/softwareVersion",
        component: () => import("../views/software/softwareVersion"),
      },
    ],
  },
  {
    path: "/menu",
    redirect: "/menu/pictures",
    component: Home,
    children: [
      {
        path: "/menu/pictures",
        component: pictures,
      },
    ],
  },
  {
    path: "/vip",
    redirect: "/vip/vipManage",
    component: Home,
    children: [
      {
        path: "/vip/vipManage",
        component: vipManage,
      },
      {
        path: "/vip/vipOrder",
        component: vipOrder,
      },
    ],
  },
  {
    path: "/storeManagement",
    redirect: "/storeManagement/storeInfo",
    component: Home,
    children: [
      {
        path: "/storeManagement/storeInfo",
        component: () =>
          import("../views/storeManagement/storeInfoList/index.vue"),
      },
      {
        path: "/storeManagement/storeList",
        component: () => import("../views/storeManagement/storeList"),
      },
      {
        path: "/storeManagement/productList",
        component: () => import("../views/storeManagement/productList"),
      },
      {
        path: "/storeManagement/productClassif",
        component: () => import("../views/storeManagement/productClassif"),
      },
      {
        path: "/storeManagement/shopNotices",
        component: () => import("../views/storeManagement/shopNotices"),
      },
      {
        path: "/storeManagement/storeOrders",
        component: () => import("../views/storeManagement/storeOrders"),
      },
    ],
  },
  // {
  //   path: "/dictionary",
  //   redirect: "/dictionary/hotSearchWord",
  //   component: Home,
  //   children: [
  //     {
  //       path: "/dictionary/hotSearchWord",
  //       component: hotSearchWord,
  //     },
  //   ],
  // },
  {
    path: "/inspiration",
    redirect: "/inspiration/collectTabulation",
    component: Home,
    children: [
      {
        path: "/inspiration/collectTabulation",
        component: collectTabulation,
      },
      {
        path: "/inspiration/report",
        component: () => import("@/views/report/report.vue"),
      },
      {
        path: "/inspiration/boardTabulation",
        component: boardTabulation,
      },
      {
        path: "/inspiration/drawingBoardClassify",
        component: drawingBoardClassify,
      },
    ],
  },
  {
    path: "/friendshipLink",
    redirect: "/friendshipLink/boardTabulation",
    component: Home,
    children: [
      {
        path: "/friendshipLink/linkList",
        component: linkList,
        name: "linkList",
        menu_name: "链接列表",
      },
      {
        path: "/friendshipLink/classify",
        component: classify,
        name: "classify",
        menu_name: "链接分类",
      },
    ],
  },
  {
    path: "/designerCertification",
    redirect: "/designerCertification/personal",
    component: Home,
    children: [
      {
        path: "/designerCertification/personal",
        component: () => import("@/views/personalAuthentication"),
        name: "personal",
        menu_name: "个人设计师认证",
      },
      {
        path: "/designerCertification/enterprise",
        component: () => import("@/views/enterpriseCertification"),
        name: "enterprise",
        menu_name: "企业设计师认证",
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});
// 导航守卫
router.beforeEach((to, from, next) => {
  // to:将要访问的路由
  // from:路由从哪里来
  // next:放行 next():直接放行  next('/login'):强制跳转到'./login'
  if (to.path === "/login") return next();
  const token = window.sessionStorage.getItem("token");
  if (!token) return next("/login");
  next();
});

export default router;
