<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>画板</el-breadcrumb-item>
            <el-breadcrumb-item>画板分类</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <el-row :gutter="20">
                <el-col :span="4">
                    <el-button type="primary" @click="addRawingClassify">添加画板分类</el-button>
                </el-col>
            </el-row>
            <el-table :data="classList" border stripe>
                <el-table-column label="画板分类ID" prop="drawing_board_category_id"></el-table-column>
                <el-table-column label="分类名称" prop="category_name"></el-table-column>
                <el-table-column label="创建时间" prop="create_time"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" icon="el-icon-edit" size="mini"
                            @click="updateDrawingClassify(scope.row)"></el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <el-dialog :title="classInformation.drawing_board_category_id ? '修改画板分类' : '添加画板分类'"
            :visible.sync="dialogFormVisible" width="750px">
            <el-form style="width: 80%;" label-position="right" label-width="120px">
                <el-form-item label="画板分类名称：">
                    <el-input placeholader="请输入画板分类名称" v-model="classInformation.category_name"></el-input>
                </el-form-item>
                <el-form-item label="排序：">
                    <el-input-number v-model="classInformation.sort" controls-position="right" :min="1"
                        :max="100"></el-input-number>
                </el-form-item>
                <el-form-item label="是否启用：">

                    <el-radio-group v-model="classInformation.is_use">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirm">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            classList: [],
            dialogFormVisible: false,
            classInformation: {
                category_name: "",
                sort: ""
            }
        }
    },
    methods: {
        async reqdrawingBoardClassifyAll() {
            const { data: res } = await this.$http.post('drawingBoardCategory/list')
            this.classList = res.data
        },
        addRawingClassify() {
            this.classInformation.category_name = ""
            this.classInformation.sort = ""
            this.classInformation.drawing_board_category_id = ""
            this.dialogFormVisible = true
        },
        async reqAddOrUpdateClassify(data) {
            if (data.drawing_board_category_id) {
                return this.$http.post('drawingBoardCategory/update', data)
            } else {
                return this.$http.post('drawingBoardCategory/add', data)
            }
        },

        updateDrawingClassify(row) {
            this.dialogFormVisible = true
            Object.assign(this.classInformation, row)
            console.log(this.classInformation, row)
        },
        async confirm() {
            const { data: res } = await this.reqAddOrUpdateClassify(this.classInformation)
            if (res.code == 0) {
                this.$message.success(this.classInformation.drawing_board_category_id ? "修改成功" : "添加成功")
            } else {
                this.$message.error(this.classInformation.drawing_board_category_id ? "修改失败" : "添加失败")
            }
            this.dialogFormVisible = false
            this.reqdrawingBoardClassifyAll()
        },

    },
    mounted() {
        this.reqdrawingBoardClassifyAll()
    }
}
</script>

<style lang="scss" scoped></style>