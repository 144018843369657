<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>用户实名认证审核列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="20">
        <el-col :span="7">
          <el-input clearable placeholder="请输入内容" v-model="queryinfo.order_no">
            <el-button slot="append" icon="el-icon-search" @click="searchChange()"></el-button>
          </el-input>
        </el-col>
        <el-col :span="5">
          <el-select v-model="queryinfo.check_status" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-table :data="userlist" border stripe>
        <!-- <el-table-column label="素材ID" prop="id"></el-table-column> -->
        <!-- <el-table-column label="审核员ID" prop="checker_id"></el-table-column> -->

        <el-table-column label="用户的实名认证id" prop="identity_id"></el-table-column>
        <el-table-column label="用户id" prop="applicant_id"></el-table-column>
        <el-table-column label="身份证国徽面图片的网址">
          <template slot-scope="scope">
            <el-image :src="scope.row.national_emblem_image_url" style="width: 100px;height: auto;"
              :preview-src-list="[scope.row.national_emblem_image_url]"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="身份证人像面图片的网址">
          <template slot-scope="scope">
            <el-image :src="scope.row.human_image_url" style="width: 100px;height: auto;"
              :preview-src-list="[scope.row.human_image_url]"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="手持身份证图片的网址">
          <template slot-scope="scope">
            <el-image :src="scope.row.hand_held_image_url" style="width: 100px;height: auto;"
              :preview-src-list="[scope.row.hand_held_image_url]"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="姓名" prop="full_name"></el-table-column>
        <el-table-column label="身份证号" prop="id_no"></el-table-column>
        <el-table-column label="审核状态" prop="check_status_text"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" size="mini"
              @click="showEditDialog(scope.row.identity_id)"></el-button>
            <!-- <el-button
              type="primary"
              icon="el-icon-delete"
              size="mini"
              @click="deleteEditDialog(scope.row.id)"
            ></el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <!--分页区域-->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryinfo.page" :page-sizes="[5, 10, 15, 20]" :page-size="queryinfo.list_rows"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </el-card>
    <!-- 修改文章信息 -->
    <el-dialog title="查看素材信息" :visible.sync="editDialogVisible" width="50%" @closed="editDialogClosed">
      <el-form :model="editForm" :rules="editRules" ref="editFormRef" label-width="120px">
        <el-form-item label="用户认证id" prop="identity_id">
          <el-input v-model="editForm.identity_id" disabled></el-input>
        </el-form-item>
        <el-form-item label="用户姓名" prop="full_name">
          <el-input v-model="editForm.full_name" disabled></el-input>
        </el-form-item>
        <el-form-item label="审核" prop="reviewStatus">
          <el-radio v-model="reviewStatus.value" label="1">通过</el-radio>
          <el-radio v-model="reviewStatus.value" label="2">驳回</el-radio>
        </el-form-item>
        <el-form-item label="审核结果的理由" prop="check_reason">
          <el-input v-model="reviewStatus.check_reason"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editRoles">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 获取文章信息列表
      queryinfo: {
        order_no: "",
        page: 1,
        list_rows: 5,
        check_status: ""
      },
      userlist: [],
      total: 0,
      //控制创建文章对话框展示与隐藏
      addDialogVisible: false,
      addForm: {
        title: "",
        editor: "",
        body: "",
        // 上传的图片临时路径
        pics: [],
      },
      addRules: {
        title: [
          {
            required: true,
            message: "请输入文章标题",
            trigger: "blur",
          },
        ],
        editor: [
          {
            required: true,
            message: "请输入作者名称",
            trigger: "blur",
          },
        ],
        body: [
          {
            required: true,
            message: "请输入文本内容",
            trigger: "blur",
          },
        ],
      },
      editDialogVisible: false,
      options: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "wait_check",
          label: "待审核",
        },
        {
          value: "pass",
          label: "审核通过",
        },
        {
          value: "reject",
          label: "驳回",
        },
      ],
      editForm: {},
      editRules: {
        title: [
          {
            required: true,
            message: "请输入文章标题",
            trigger: "blur",
          },
        ],
        editor: [
          {
            required: true,
            message: "请输入作者名称",
            trigger: "blur",
          },
        ],
        source: [
          {
            required: true,
            message: "请输入文章来源",
            trigger: "blur",
          },
        ],
        body: [
          {
            required: true,
            message: "请输入文本内容",
            trigger: "blur",
          },
        ],

      },
      // 上传图片的url
      uploadUrl: "http://127.0.0.1:8887/api/upload",
      // 图片上传组件的headers请求头对象
      headerObj: {
        Authorization: window.sessionStorage.getItem("token"),
      },
      previewPath: "",
      previewVisible: false,
      statusValue: "1",
      reviewStatus: {
        identity_id: "",
        value: "1",
        check_reason: ""
      }
    };
  },
  created() {
    this.getinforList();
  },
  methods: {
    // 获取所有菜单
    async getinforList() {
      const { data: res } = await this.$http.post("identity/index", this.queryinfo);
      if (res.code !== 0) return this.$message.err("获取实名认证列表失败!");
      this.userlist = res.data.data;
      this.total = res.data.total;
    },
    // 监听handleSizeChange方法
    handleSizeChange(newsize) {
      this.queryinfo.list_rows = newsize;
      this.getinforList();
    },
    // 监听handleCurrentChange方法
    handleCurrentChange(newpage) {
      this.queryinfo.page = newpage;
      this.getinforList();
    },
    // 搜索方法
    searchChange() {
      this.queryinfo.page = 1;
      this.getinforList();
    },
    // 添加用户关闭
    addDialogClosed() {
      this.$refs["addFormRef"].resetFields();
    },
    //删除用户
    async deleteEditDialog(id) {
      // console.log(id);
      const confirmRessult = await this.$confirm(
        "此操作将永久删除, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);

      if (confirmRessult !== "confirm") {
        return this.$message.info("已经取消删除!");
      }
      const { data: res } = await this.$http.post("drawingBoard/delete", {
        id: id,
      });
      if (res.code !== 0) {
        return this.$message.error("删除失败!");
      }
      console.log(res);
      this.$message.success("删除成功!");
      this.getinforList();
    },
    // 点击用户认证审核
    async showEditDialog(id) {
      this.editDialogVisible = true;
      this.reviewStatus.check_reason = ""
      this.reviewStatus.identity_id = id
      const { data: res } = await this.$http.post("identity/detail", {
        identity_id: id,
      });
      if (res.code !== 0) return this.$message.error("查询失败");

      this.editForm = res.data;
      //   if (this.editForm.check_status == 2) {
      //     this.editForm.check_status = "审核通过";
      //   } else if (this.editForm.check_status == 3) {
      //     this.editForm.check_status = "驳回";
      //   }
    },
    editDialogClosed() {
      this.$refs["editFormRef"].resetFields();
    },
    editUser() {
      // 预验证
      this.$refs.editFormRef.validate(async (valid) => {
        if (!valid) return;
        // 调用后台数据创建用户
        const { data: res } = await this.$http.post(
          "updateWikis/" + this.editForm.id,
          this.editForm
        );
        if (res.code !== 0) return this.$message.error(res.meta.msg);
        this.$message.success("编辑成功");
        //    关闭添加窗口
        this.editDialogVisible = false;
        this.getinforList();
      });
    },
    // 审核确认按钮
    editRoles() {
      this.$refs.editFormRef.validate(async (valid) => {
        if (!valid) return;
        if (this.reviewStatus.value == "1") {
          const { data: res } = await this.$http.post(`identity/pass`, this.reviewStatus);
          if (res.code !== 0) return this.$message.error(res.message);
          this.$message.success("修改审核状态成功!");
          this.getinforList();
          this.editDialogVisible = false;
        } else {
          const { data: res } = await this.$http.post(`identity/reject`, this.reviewStatus);
          if (res.code !== 0) return this.$message.error(res.message);
          this.$message.success("修改审核状态成功!");
          this.getinforList();
          this.editDialogVisible = false;
        }

      });
    },
    //修改使用状态
    async modifyUseStatus(id, status) {
      const { data: res } = await this.$http.post("drawingBoard/modifyUseStatus", {
        id: id,
        use_status: status,
      });
      this.$message.success(res.message);
      //   this.getinforList();
    },
  },
};
</script>

<style lang="less" scoped>
.el-image {
  width: 100px;
  height: 160px;
  object-fit: cover;
}
</style>