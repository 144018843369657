<template>
    <el-container class="home-container">
        <!--头部主题区-->
        <el-header>
            <div>
                <img src="../assets/logo1.png" alt="">
                <span>华夏廊图网后台管理系统</span>
            </div>
            <el-button type="info" @click="logout">退出</el-button>
        </el-header>
        <!--页面主题区-->
        <el-container>
            <!--侧边栏-->
            <el-aside width="230px" class="aside">
                <!-- <div class="toggle-button" @click="toggleCollapse">|||</div> -->
                <!--侧边栏菜单区-->
                <el-menu background-color="#333744" text-color="#fff" active-text-color="#4d9efe" unique-opened
                    :collapse="isCollapse" :collapse-transition="false" router>
                    <Menu :menuList="menulist"></Menu>
                    <!--一级菜单-->
                    <!-- <el-menu-item :index="'/' + menuItem.path" v-for="menuItem in menulist" :key='menuItem.admin_menu_id'>
                        <i class="el-icon-menu"></i>

                        <template slot="title">


                            <span>{{ menuItem.menu_name }}</span>
                        </template>
</el-menu-item> -->
                    <!-- <el-submenu :index="menuItem.id+''" v-for="menuItem in menulist" :key="menuItem.admin_menu_id">
                     
                        <template slot="title">
                          
                            <i :class="iconsObj[menuItem.admin_menu_id]"></i>
                           
                            <span>{{menuItem.menu_name}}</span>
                        </template>
                     
                        <el-menu-item :index="'/'+submenuItem.path" v-for="submenuItem in menuItem.children" :key='submenuItem.id'>
                          
                            <template slot="title">
                               
                                <i class="el-icon-menu"></i>
                                
                                <span>{{submenuItem.menu_name}}</span>
                            </template>
                        </el-menu-item>
                    </el-submenu> -->
                </el-menu>
            </el-aside>
            <!--右侧内容主题-->
            <el-main style="height: calc(100vh - 60px);">
                <!--路由占位符-->
                <router-view></router-view>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
import Menu from "./menu/index.vue"
export default {
    components: { Menu },
    data() {
        return {
            // 左侧菜单数据
            menulist: [],
            iconsObj: {
                '1': 'iconfont icon-shangpin',
                '2': 'iconfont icon-danju',
                '3': 'iconfont icon-tijikongjian',
                '4': 'iconfont icon-user',
                '18': 'iconfont icon-baobiao',
            },
            // 不折叠
            isCollapse: false,
            router: [
                {
                    path: '/user',
                    route: 'user',
                    menu_name: '用户管理',
                    icon: 'el-icon-user-solid',
                    children: [
                        {
                            path: '/user/administrator',
                            route: 'administrator',
                            menu_name: '管理员'
                        },
                        {
                            path: '/user/userlist',
                            route: 'userlist',
                            menu_name: '用户列表'
                        },

                        {
                            path: '/user/role',
                            route: 'role',
                            menu_name: '角色列表'
                        },
                        {
                            path: '/user/certificationList',
                            route: 'certificationList',
                            menu_name: '用户实名认证'
                        },
                        {
                            path: '/user/career',
                            route: 'career',
                            menu_name: '用户职业'
                        },
                        {
                            path: '/user/accd',
                            route: 'accd',
                            menu_name: '设计师认证'
                        }
                    ]
                },
                {
                    path: '/admin',
                    route: 'admin',
                    menu_name: '菜单管理',
                    icon: 'el-icon-menu'
                },
                {
                    path: '/material',
                    route: 'material',
                    menu_name: '素材',
                    icon: 'el-icon-picture',
                    children: [
                        {
                            path: '/material/materialTabulation',
                            route: 'materialTabulation',
                            menu_name: '素材列表'
                        },
                        {
                            path: '/material/materialClassify',
                            route: 'materialClassify',
                            menu_name: '素材分类'
                        }
                    ]
                },
                {
                    path: "/wikipedia",
                    route: 'wikipedia',
                    menu_name: '廊币充值',
                    icon: 'el-icon-coin'
                },
                {
                    path: '/hotTopics',
                    route: 'hotTopics',
                    menu_name: '热门问题',
                    icon: 'el-icon-question',
                },
                {

                    path: "/menu",
                    route: 'menu',
                    menu_name: '菜谱管理',
                    icon: 'el-icon-document-copy',
                    children: [
                        {
                            path: '/menu/pictures',
                            route: 'pictures',
                            menu_name: '轮播管理'
                        },
                    ]
                },
                {
                    path: "/vip",
                    route: 'vip',
                    menu_name: 'Vip',
                    icon: 'el-icon-share',
                    children: [
                        {
                            path: '/vip/vipManage',
                            route: 'vipManage',
                            menu_name: '会员套餐',
                        },
                        {
                            path: '/vip/vipOrder',
                            route: 'vipOrder',
                            menu_name: '订单列表'
                        }
                    ]
                },
                {
                    path: "/dictionary",
                    route: 'dictionary',
                    menu_name: '词典管理',
                    icon: 'el-icon-collection',
                    children: [

                        {
                            path: '/dictionary/hotSearchWord',
                            route: 'hotSearchWord',
                            menu_name: '热搜词',
                        }
                    ]
                },
                {
                    path: "/gather",
                    route: "gather",
                    menu_name: '采集',
                    icon: 'el-icon-scissors',
                    children: [
                        {
                            path: '/gather/collectTabulation',
                            route: '/collectTabulation',
                            menu_name: '采集列表'
                        },

                    ]
                },
                {
                    path: '/drawingBoard',
                    name: 'drawingBoard',
                    menu_name: '画板',
                    icon: 'el-icon-edit',
                    children: [
                        {
                            path: '/drawingBoard/boardTabulation',
                            name: 'boardTabulation',
                            menu_name: '画板列表'
                        },
                        {
                            path: '/drawingBoard/drawingBoardClassify',
                            name: 'drawingBoardClassify',
                            menu_name: '画板分类'
                        }
                    ]
                },
                {
                    path: '/friendshipLink',
                    name: 'friendshipLink',
                    menu_name: '友情链接',
                    icon: 'el-icon-s-promotion',
                    children: [
                        {
                            path: '/friendshipLink/linkList',
                            name: 'linkList',
                            menu_name: '链接列表'
                        },
                        {
                            path: '/friendshipLink/classify',
                            name: 'classify',
                            menu_name: '链接分类'
                        }
                    ]
                },
                {
                    path: '/industryInformation',
                    name: 'industryInformation',
                    menu_name: '行业咨询',
                    icon: 'el-icon-message-solid'
                },
                {
                    path: '/withdrawCashLog',
                    name: 'withdrawCashLog',
                    menu_name: '提现记录',
                    icon: 'el-icon-s-order'
                },
                {
                    path: '/materialOrder',
                    name: 'materialOrder',
                    menu_name: '素材订单',
                    icon: 'el-icon-reading'
                },
                {
                    path: '/notice',
                    name: 'notice',
                    menu_name: '公告',
                    icon: 'el-icon-coordinate'
                },
                {
                    path: '/greet',
                    name: 'greet',
                    menu_name: '欢迎词',
                    icon: 'el-icon-takeaway-box'
                }
            ]
        }
    },
    created() {
        this.getMenuList();
        console.log(this.$route)
    },
    methods: {
        logout() {
            window.sessionStorage.clear()//清除token
            this.$router.push('/login')
        },
        // 获取所有菜单
        async getMenuList() {
            const { data: res } = await this.$http.post('adminMenu/catalog')
            // if(res.status!==200) return this.$message.errer(res.meta.msg)
            this.menulist = res.data
            console.log(this.menulist);

        },
        // 点击按钮，进行折叠和展开
        toggleCollapse() {
            this.isCollapse = !this.isCollapse
        }
    },
}
</script>

<style lang="less" scoped>
.home-container {
    height: 100%;
}

.el-header {
    background-color: #333744;
    display: flex;
    justify-content: space-between;
    padding-left: 0;
    align-items: center;
    color: #fff;
    font-size: 20px;

    >div {
        display: flex;
        align-items: center;

        img {
            width: 40px;
            margin-left: 20px;
        }

        span {
            margin-left: 15px;
        }
    }
}

.el-aside {
    background-color: #333744;

    .el-menu {
        border-right: none;
    }
}

.el-main {
    background-color: #eaedf1;
}

.iconfont {
    margin-right: 10px;
}

.toggle-button {
    background-color: #4a5064;
    font-size: 10px;
    line-height: 24px;
    color: #fff;
    text-align: center;
    letter-spacing: 0.2em;
    cursor: pointer;

}

.aside {
    overflow: auto;
    height: calc(100vh - 60px);
}

.aside::-webkit-scrollbar {
    width: 0;
    /* 隐藏滚动条 */
}
</style>