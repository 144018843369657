<template>
    <div>

        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>菜谱管理</el-breadcrumb-item>
            <el-breadcrumb-item>轮播管理</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <el-row :gutter="20">
                <el-col :span="5">
                    <el-input clearable placeholder="请输入内容" v-model="queryinfo.title">
                        <el-button slot="append" icon="el-icon-search" @click="reqBanner"></el-button>
                    </el-input>
                </el-col>
                <el-col :span="3.5">
                    <el-select v-model="queryinfo.banner_type" placeholder="请选择" @change="reqBanner">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="4">
                    <el-button type="primary" @click="addBanner">添加</el-button>
                </el-col>
            </el-row>
            <el-table :data="bannerList" border stripe>
                <el-table-column label="轮播图ID" prop="banner_id"></el-table-column>
                <el-table-column label="标题" prop="title"></el-table-column>
                <el-table-column label="排序" prop="sort"></el-table-column>
                <el-table-column label="图片预览">
                    <template slot-scope="scope">
                        <el-image style="width: 60px; height: 60px" :src="scope.row.image_url"
                            :preview-src-list="[scope.row.image_url]">
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column label="导航地址" prop="navigation_url"></el-table-column>
                <el-table-column label="上传时间" prop="create_time"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" icon="el-icon-edit" size="mini" @click="updateBanner(scope.row)">
                        </el-button>
                        <el-button type="danger" icon="el-icon-delete" size="mini"
                            @click="deletBanner(scope.row.banner_id)">
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="queryinfo.page" :page-sizes="[5, 10, 15, 20]" :page-size="queryinfo.list_rows"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </el-card>
        <el-dialog title="添加" :visible.sync="dialogFormVisible" width="650px">
            <el-form v-model="addForm" label-width="100px" style="width: 80%;">
                <el-form-item label="标题：" prop="title">
                    <el-input v-model="addForm.title" />
                </el-form-item>
                <el-form-item label="图片预览：">
                    <el-upload border class="avatar-uploader" action="https://api.hxltw.cn/admin/AliCloudOss/image"
                        :auto-upload="false" :headers="headerObj" :show-file-list="false" :on-change="handleChange">
                        <img v-if="addForm.image_url" :src="addForm.image_url" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="导航地址：" prop="navigation_url">
                    <el-input v-model="addForm.navigation_url" />
                </el-form-item>
                <el-form-item label="排序：" prop="sort">
                    <el-input v-model="addForm.sort" />
                </el-form-item>
                <el-form-item label="排序：" prop="sort">
                    <el-radio v-model="addForm.banner_type" label='drawing'>美图</el-radio>
                    <el-radio v-model="addForm.banner_type" label="material">素材</el-radio>
                </el-form-item>

            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="confirm">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            queryinfo: {
                title: '',
                page: 1,
                list_rows: 5,
                type: 1,
                banner_type: 'drawing'
            },
            bannerList: [],
            total: 0,
            addForm: {
                title: "",
                image_url: "",
                image_file_id: "",
                navigation_url: "",
                sort: "",
                banner_type: 'drawing'
            },
            dialogFormVisible: false,
            headerObj: {
                Authorization: window.sessionStorage.getItem('token')
            },
            options: [
                {
                    value: 'drawing',
                    label: '美图'
                },
                {
                    value: 'material',
                    label: '素材'
                }],
        }
    },
    created() {

    },
    methods: {
        async reqBanner() {
            const { data: res } = await this.$http.post('banner/index', this.queryinfo)
            this.bannerList = res.data.data
            this.total = res.data.total
        },
        addOrUpdateBanner(data) {
            if (data.banner_id) {
                return this.$http.post('banner/update', data)
            } else {
                return this.$http.post('banner/add', data)
            }
        },
        addBanner() {
            this.addForm.banner_id = ""
            this.addForm.title = ""
            this.addForm.image_url = ""
            this.addForm.image_file_id = ""
            this.addForm.navigation_url = ""
            this.addForm.sort = ""
            this.addForm.banner_type = 1
            this.dialogFormVisible = true
        },
        handleChange(file) {
            if (file.raw.type == "image/png" || file.raw.type == "image/jpeg" || file.raw.type == "image/webp") {
                this.doUploadImg(file)
            } else {
                this.$message.error("请上传图片格式")
                this.fileList = []
            }
            // const {data:res} = await this.$http.post("AliCloudOss/image")
            // console.log(res)
        },
        async doUploadImg(file) {
            let formData = new FormData()
            formData.append('image', file.raw)
            const { data: res } = await this.$http.post("AliCloudOss/image", formData)
            this.addForm.image_url = res.data.url
            this.addForm.image_file_id = res.data.file_id
        },
        async confirm() {
            const { data: res } = await this.addOrUpdateBanner(this.addForm)
            if (res.code == 0) {
                this.$message.success(this.addForm.banner_id ? '修改成功' : '添加成功')
                this.reqBanner()
            } else {
                this.$message.error(this.addForm.banner_id ? '修改失败' : '添加失败')
            }
            this.dialogFormVisible = false
        },
        cancel() {
            this.dialogFormVisible = false
        },
        async deletBanner(id) {
            const confirmResult = await this.$confirm(
                "此操作将永久删除该链接, 是否继续?",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            ).catch((err) => err);
            // console.log(row);
            if (confirmResult !== "confirm")
                return this.$message.info("已经取消删除！");
            const { data: res } = await this.$http.post('banner/delete', { banner_id: id })
            if (res.code == 0) {
                this.$message.success('删除成功')
                this.reqBanner()
            } else {
                this.$message.error('删除失败')
            }
        },
        updateBanner(row) {
            Object.assign(this.addForm, row)
            this.dialogFormVisible = true
        },
        handleSizeChange(newsize) {
            this.queryinfo.list_rows = newsize
            this.reqBanner()
        },
        handleCurrentChange(newpage) {
            this.queryinfo.page = newpage
            this.reqBanner()
        },
    },
    mounted() {
        this.reqBanner()
    }
}
</script>

<style lang="less" scoped>
::v-deep .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

::v-deep .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
</style>