<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>会员套餐</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row>
        <el-col>
          <el-button type="primary" @click="addDialogVisible = true">添加套餐</el-button>
        </el-col>
      </el-row>
      <!-- 角色列表区域 -->
      <el-table :data="rolelist" border stripe>
        <el-table-column label="套餐名称" prop="vip_name"></el-table-column>
        <el-table-column label="套餐简介" prop="description"></el-table-column>
        <!-- <el-table-column
          label="会员套餐的图片的网址"
          prop="material_vip_image_url"
        >
          <img :src="material_vip_image_url" alt="" />
        </el-table-column> -->
        <el-table-column label="折扣" prop="discount"></el-table-column>
        <el-table-column label="套餐价格" prop="sale_price"></el-table-column>
        <el-table-column label="是否上架">
          <template slot-scope="scope">
            <el-switch :active-value="1" :inactive-value="0" v-model="scope.row.is_sale"
              @change="changeState(scope.row.material_vip_id, scope.row.is_sale)">
            </el-switch>
          </template>
        </el-table-column>
        <!-- <el-table-column label="套餐折扣" prop="discount"></el-table-column> -->
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" icon="el-icon-edit"
              @click="showEditDialog(scope.row.material_vip_id)">编辑</el-button>
            <!-- <el-button size="mini" type="danger" icon="el-icon-delete" @click="removeRoles(scope.row)">删除</el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 添加套餐 -->
    <el-dialog title="添加套餐" :visible.sync="addDialogVisible" width="50%" @close="addDialogClosed">
      <el-form ref="addFormRef" :model="addForm" :rules="addFormRules" label-width="100px">
        <el-form-item label="套餐名称" prop="vip_name">
          <el-input v-model="addForm.vip_name"></el-input>
        </el-form-item>
        <el-form-item label="套餐简介" prop="description">
          <el-input v-model="addForm.description"></el-input>
        </el-form-item>
        <el-form-item label="会员图片">
          <el-upload class="upload-demo" action="https://api.hxltw.cn/admin/AliCloudOss/image" :auto-upload="false"
            :file-list="fileList" :headers="headers" :on-change="handleChange" :limit="1">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="套餐有效期" prop="valid_time_unit">
          <!-- <el-input v-model="addForm.valid_time_unit"></el-input> -->
          <el-select v-model="addForm.valid_time_unit" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="套餐下载次数" prop="apply_download_amount">
          <el-input v-model="addForm.apply_download_amount"></el-input>
        </el-form-item>
        <el-form-item label="套餐价格" prop="sale_yuan">
          <el-input v-model="addForm.sale_yuan"></el-input>
        </el-form-item>
        <el-form-item label="套餐排序" prop="sort">
          <el-input v-model="addForm.sort"></el-input>
        </el-form-item>
        <el-form-item label="套餐折扣" prop="discount">
          <el-input v-model="addForm.discount"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addRoles()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改套餐 -->
    <el-dialog title="修改套餐" :visible.sync="editDialogVisible" width="50%" @close="editDialogClosed">
      <el-form ref="editFormRef" :model="editForm" :rules="editFormRules" label-width="100px">
        <el-form-item label="套餐名称" prop="vip_name">
          <el-input v-model="editForm.vip_name"></el-input>
        </el-form-item>
        <el-form-item label="套餐简介" prop="description">
          <el-input v-model="editForm.description"></el-input>
        </el-form-item>
        <el-form-item label="会员套餐的图片的网址" prop="image_url">
          <el-input v-model="editForm.image_url"></el-input>
        </el-form-item>
        <el-form-item label="套餐有效期" prop="valid_time_unit" value-key="valid_time_unit_text">
          <el-select v-model="editForm.valid_time_unit" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="套餐下载次数" prop="apply_download_amount">
          <el-input v-model="editForm.apply_download_amount"></el-input>
        </el-form-item>
        <el-form-item label="套餐价格" prop="sale_yuan">
          <el-input v-model="editForm.sale_yuan"></el-input>
        </el-form-item>
        <el-form-item label="套餐排序" prop="sort">
          <el-input v-model="editForm.sort"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editRoles()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rolelist: [],
      addDialogVisible: false,
      addForm: {
        material_vip_name: "", //素材会员名称
        description: "", //简介
        image_url: "", //素材会员图片的网址
        image_file_id: "",
        valid_time_unit: "", //有效时长的单位：1月；2季；3年
        valid_time_unit_text: "", //
        apply_download_amount: "", //提供的下载次数
        sale_yuan: "", //销售价格
        sort: "", //排序
        discount: "", //折扣
      },
      options: [
        {
          value: "1",
          label: "月",
        },
        {
          value: "2",
          label: "季",
        },
        {
          value: "3",
          label: "年",
        },
        {
          value: "4",
          label: "终生"
        }
      ],
      addFormRules: {
        roleName: [
          {
            required: true,
            message: "请输入参数名称",
            trigger: "blur",
          },
        ],
        roleDesc: [
          {
            required: true,
            message: "请输入参数名称",
            trigger: "blur",
          },
        ],
      },
      editDialogVisible: false,
      editForm: {
        material_vip_id: "",
        vip_name: "", //素材会员名称
        description: "", //简介
        image_url: "", //素材会员图片的网址
        valid_time_unit: "", //有效时长的单位：1月；2季；3年
        valid_time_unit_text: "", //
        apply_download_amount: "", //提供的下载次数
        sale_yuan: "", //销售价格
        sort: "", //排序
        discount: "", //折扣
      },
      editFormRules: {
        roleName: [
          {
            required: true,
            message: "请输入参数名称",
            trigger: "blur",
          },
        ],
        roleDesc: [
          {
            required: true,
            message: "请输入参数名称",
            trigger: "blur",
          },
        ],
      },
      fileList: [],
      headers: { Authorization: "Bearer " + window.sessionStorage.getItem('token') }
    };
  },
  created() {
    this.getRolesList();
  },
  methods: {
    // 获取所有菜单
    async getRolesList() {
      const { data: res } = await this.$http.post("materialVip/list");
      if (res.code !== 0) return this.$message.error("获取套餐列表失败!");
      this.rolelist = res.data;
      console.log(this.rolelist);
    },
    addDialogClosed() {
      this.$refs.addFormRef.resetFields();
    },
    //添加会员套餐
    addRoles() {
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) return;
        const { data: res } = await this.$http.post(`materialVip/add`, {
          vip_name: this.addForm.vip_name,
          description: this.addForm.description,
          image_url: this.addForm.image_url,
          image_file_id: this.addForm.image_file_id,
          valid_time_unit: this.addForm.valid_time_unit,
          apply_download_amount: this.addForm.apply_download_amount,
          sale_yuan: this.addForm.sale_yuan,
          sort: this.addForm.sort,
          discount: this.addForm.discount,
        });
        if (res.code !== 0) return this.$message.error(res.message);
        this.$message.success("添加套餐成功!");
        this.getRolesList();
        this.addDialogVisible = false;
      });
    },
    // 删除按钮
    async removeRoles(row) {
      const confirmResult = await this.$confirm(
        "此操作将永久删除该套餐, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      // console.log(row);
      if (confirmResult !== "confirm")
        return this.$message.info("已经取消删除！");
      const { data: res } = await this.$http.post(`materialVip/delete`, { material_vip_id: row.material_vip_id });
      if (res.code !== 0) return this.$message.error("删除套餐失败!");
      this.$message.success("删除套餐成功!");
      this.getRolesList();
    },
    //编辑按钮
    async showEditDialog(id) {
      const { data: res } = await this.$http.post("materialVip/detail", {
        material_vip_id: id,
      });
      if (res.code !== 0) {
        return this.$message.error("编辑会员套餐失败");
      }
      this.editForm = res.data;
      console.log(this.editForm);
      if (this.editForm.valid_time_unit == 1) {
        this.editForm.valid_time_unit = "月";
      } else if (this.editForm.valid_time_unit == 2) {
        this.editForm.valid_time_unit = "季";
      } else if (this.editForm.valid_time_unit == 3) {
        this.editForm.valid_time_unit = "年";
      }
      this.editDialogVisible = true;
    },
    editDialogClosed() {
      this.$refs.editFormRef.resetFields();
    },
    // 编辑确认按钮
    editRoles() {
      if (this.editForm.valid_time_unit == "月") {
        this.editForm.valid_time_unit = 1;
      } else if (this.editForm.valid_time_unit == "季") {
        this.editForm.valid_time_unit = 2;
      } else if (this.editForm.valid_time_unit == "年") {
        this.editForm.valid_time_unit = 3;
      }
      this.$refs.editFormRef.validate(async (valid) => {
        if (!valid) return;
        const { data: res } = await this.$http.post(`materialVip/update`, {
          material_vip_id: this.editForm.material_vip_id,
          vip_name: this.editForm.vip_name,
          description: this.editForm.description,
          image_url: this.editForm.image_url,
          valid_time_unit: this.editForm.valid_time_unit,
          apply_download_amount: this.editForm.apply_download_amount,
          sale_yuan: this.editForm.sale_yuan,
          sort: this.editForm.sort,
          //   discount: this.editForm.discount,
        });
        if (res.code !== 0) return this.$message.error("修改套餐信息失败!");
        this.$message.success("修改套餐信息成功!");
        this.getRolesList();
        this.editDialogVisible = false;
      });
    },
    handleChange(file) {
      console.log(file)
      if (file.raw.type == "image/png" || file.raw.type == "image/jpeg" || file.raw.type == "image/webp") {
        this.doUploadImg(file)
      } else {
        this.$message.error("请上传图片格式")
        this.fileList = []
      }
      // const {data:res} = await this.$http.post("AliCloudOss/image")
      // console.log(res)
    },
    async doUploadImg(file) {
      let formData = new FormData()
      formData.append('image', file.raw)
      const { data: res } = await this.$http.post("AliCloudOss/image", formData)
      this.addForm.image_url = res.data.url
      this.addForm.image_file_id = res.data.file_id
      console.log(res)
    },
    changeState(id, is_sale) {
      console.log(id, is_sale)
      if (is_sale == 1) {
        this.groundingVip(id)
      } else {
        this.offShelfVip(id)
      }
    },
    async groundingVip(id) {
      await this.$http.post("materialVip/onSale", { material_vip_id: id })
    },
    async offShelfVip(id) {
      await this.$http.post("materialVip/stopSale", { material_vip_id: id })
    }
  },
};
</script>

<style lang="less" scoped>
.el-tag {
  margin: 7px;
}

.bdtop {
  border-top: 1px solid #eee;
}

.bdbottom {
  border-top: 1px solid #eee;
}

.vcenter {
  display: flex;
  align-items: center;
}
</style>