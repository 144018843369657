<template>
    <div class="login_contaner">
        <div class="title">
            <div class="small">我们的使命:</div>
            <div class="big">让校园文化设计不再难!</div>
        </div>
        <div class="login_box">
            <div class="des">后台管理系统登入</div>
            <div class="avator_box">
                <img src="@/assets/login.png">
            </div>
            <el-form :model="loginFrom" :rules="rules" ref="loginFormRef" label-width="0px" class="login_form">
                <el-form-item prop="account">
                    <el-input v-model="loginFrom.account" placeholder="登入名"></el-input>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input v-model="loginFrom.password" placeholder="密码"></el-input>
                </el-form-item>
                <el-form-item class="login_btns">
                    <el-button type="danger" @click="Login"
                        style="padding: 8px 18px;border-radius: 8px;margin-right: 28px;">登&nbsp;入</el-button>
                    <el-button type="info" @click="resetForm"
                        style="padding: 8px 18px;border-radius: 8px;">重&nbsp;置</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            loginFrom: {
                account: '',
                password: ''
            },
            rules: {
                account: [{
                    required: true,
                    message: '请输入用户名',
                    trigger: 'blur'
                }, {
                    min: 3,
                    max: 16,
                    message: '长度在 3 到 16 个字符',
                    trigger: 'blur'
                }],
                password: [{
                    required: true,
                    message: '请输入密码',
                    trigger: 'blur'
                }, {
                    min: 6,
                    max: 18,
                    message: '长度在 6 到 18 个字符',
                    trigger: 'blur'
                }]
            }
        }
    },
    methods: {
        resetForm() {
            // this.loginFrom.account='zs',
            // this.loginFrom.password='123456'
            this.$refs['loginFormRef'].resetFields();
        },
        Login() {
            this.$refs.loginFormRef.validate(async valid => {
                if (!valid) return
                // console.log('正在提交');
                const {
                    data: res
                } = await this.$http.post('login/account', this.loginFrom)
                // console.log(resualts);
                if (res.code === 0) {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'success'
                    });
                    // 跳转
                    window.sessionStorage.setItem('token', 'Bearer ' + res.data.token)
                    this.$router.push('/home')
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            })
        }
    },
}
</script>
<style lang="less" scoped>
/*element ui 组件*/
/*scoprd:阻止本组件样式扩散到本组件范围内生效*/

.login_contaner {
    background-image: linear-gradient(rgb(255, 230, 231), white 80%);
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .title {
        margin: 0 auto;
        margin-top: 90px;
        color: rgb(247, 153, 177);

        .small {
            font-size: 24px;
        }

        .big {
            font-size: 72px;
        }
    }
}

.login_box {
    border: 1px solid #eee;
    width: 420px;
    height: 380px;
    background-color: white;
    border-radius: 25px;
    margin: 0 auto;
    margin-top: 72px;
    padding: 30px;
    box-sizing: border-box;

    .des {
        color: #8D8D8D;
        font-size: 18px;
    }

    .avator_box {
        text-align: center;

        img {
            width: 82%;
            margin-top: 24px;
        }
    }
}





.login_form {
    width: 90%;
    box-sizing: border-box;
    margin: 0 auto;
    margin-top: 30px;
}

.login_btns {
    text-align: center;
}
</style>