<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>用户列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="20">
        <el-col :span="5">
          <el-input clearable placeholder=" 请输入内容" v-model="queryinfo.query">
            <el-button slot="append" icon="el-icon-search" @click="getinforList"></el-button>
          </el-input>
        </el-col>
        <!-- <el-col :span="4">
          <el-button type="primary" @click="addDialogVisible = true"
            >添加文章</el-button
          >
        </el-col> -->
      </el-row>
      <el-table :data="userlist" border stripe>
        <el-table-column label="用户id" prop="user_id" width="80"></el-table-column>
        <el-table-column label="昵称" prop="nickname"></el-table-column>
        <el-table-column label="性别" prop="gender">
          <template slot-scope="scope">
            {{ scope.row.gender == 'secret' ? '保密' : scope.row.gender == 'man' ? '男' : '女' }}
          </template>
        </el-table-column>
        <el-table-column label="所在城市" prop="city"></el-table-column>

        <!-- <el-table-column label="状态管理">
          <template slot-scope="scope">
            <el-switch :active-value="1" :inactive-value="0" @change="handleChange(scope.row)"
              v-model="scope.row.is_use">
            </el-switch>
          </template>
        </el-table-column> -->
        <el-table-column label="操作">
          <template slot-scope="scope">
            <!-- <el-button type="primary" icon="el-icon-edit" size="mini"
              @click="showEditDialog(scope.row.user_id)"></el-button> -->
            <el-button type="danger" icon="el-icon-delete" size="mini"
              @click="deleteEditDialog(scope.row.user_id)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分页区域-->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryinfo.page" :page-sizes="[5, 10, 15, 20]" :page-size="queryinfo.list_rows"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </el-card>
    <!--新增文章-->
    <el-dialog title="新增管理员" :visible.sync="addDialogVisible" width="50%" @closed="addDialogClosed">
      <el-form :model="addForm" :rules="addRules" ref="addFormRef" label-width="100px">
        <el-form-item label="昵称" prop="nickname">
          <el-input v-model="addForm.nickname"></el-input>
        </el-form-item>
        <el-form-item label="管理员账号" prop="account">
          <el-input v-model="addForm.account"></el-input>
        </el-form-item>
        <el-form-item label="管理员密码 " prop="password">
          <el-input v-model="addForm.password"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="addForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="菜单id的集合" prop="role_ids">
          <el-input v-model="addForm.role_ids"></el-input>
        </el-form-item>
        <el-form-item label="角色">
          <el-checkbox-group v-model="addForm.roles_ids">
            <el-checkbox border v-for="(item, index) in userlists" :label="item.id" :key="index">{{ item.role_name
              }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUser">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改管理员信息 -->
    <!-- <el-dialog title="修改用户密码" :visible.sync="editDialogVisible" width="400px" @closed="editDialogClosed">
      <el-form :model="ids" :rules="editRules" ref="editFormRef" label-width="90px">
        <el-form-item label="新的密码" prop="password">
          <el-input v-model="ids.password"></el-input>
        </el-form-item>
        <el-form-item label="重复密码" prop="duplicatePassword">
          <el-input v-model="ids.duplicatePassword"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editUser">确 定</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 获取文章信息列表
      queryinfo: {
        query: "",
        page: 1,
        list_rows: 5,
      },
      checkboxGroup1: [],
      usestatuss: {},
      usestatustwo: {},
      userlist: [],
      cascaderProps: {
        label: "role_name", //当返回的数据字段不是label和value可以改成实际的字段比如goodsName
        value: "id",
      },
      userlists: [],
      usestatus: null,
      total: 0,
      //控制创建文章对话框展示与隐藏
      addDialogVisible: false,
      addForm: {
        role_name: "",
        menu_ids: "",
        roles_ids: "",
      },
      addRules: {
        role_name: [
          {
            required: true,
            message: "请输入角色名称",
            trigger: "blur",
          },
        ],
        menu_ids: [
          {
            required: true,
            message: "请输入菜单id集合",
            trigger: "blur",
          },
        ],
      },
      editDialogVisible: false,
      editForm: {},
      editRules: {
        password: [
          {
            required: true,
            message: "请输入新的密码",
            trigger: "blur",
          },
        ],
        duplicatePassword: [
          {
            required: true,
            message: "请输入重复密码",
            trigger: "blur",
          },
        ],
      },
      ids: {
        user_id: 0,
        password: "",
        duplicatePassword: ""
      },
      // 图片上传组件的headers请求头对象
      headerObj: {
        Authorization: window.sessionStorage.getItem("token"),
      },
      previewPath: "",
      previewVisible: false,
    };
  },
  mounted() {
    this.getinforList();
    // this.getinforLists();
  },
  methods: {
    // 获取所管理员
    async getinforList() {
      const { data: res } = await this.$http.post("user/index", {
        page: this.queryinfo.page,
        list_rows: this.queryinfo.list_rows,
      });

      if (res.code !== 0) return this.$message.err("获取拓展信息列表失败!");
      this.userlist = res.data.data;
      this.total = res.data.total;
      console.log(this.userlist);
    },
    // 获取所有菜单
    async getinforLists() {
      const { data: res } = await this.$http.get("adminRole/index", {
        params: this.queryinfo,
      });

      if (res.code !== 0) return this.$message.err("获取拓展信息列表失败!");
      this.userlists = res.data.data;
      this.total = res.data.total;
      console.log(this.userlists);
    },
    // 监听handleSizeChange方法
    handleSizeChange(newsize) {
      this.queryinfo.list_rows = newsize;
      this.getinforList();
    },
    // 监听handleCurrentChange方法
    handleCurrentChange(newpage) {
      this.queryinfo.page = newpage;
      this.getinforList();
    },
    // 添加用户关闭
    addDialogClosed() {
      this.$refs["addFormRef"].resetFields();
    },
    async handleChange(row) {
      if (row.is_use == 0) {
        await this.$http.post('user/stopUse', { user_id: row.user_id })
      } else {
        await this.$http.post('user/allowUse', { user_id: row.user_id })
      }
    },
    addUser() {
      // 预验证
      console.log(1245, this.addForm);
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) return;
        // 调用后台数据创建用户
        const { data: res } = await this.$http.post(
          "adminRole/add",
          this.addForm
        );
        if (res.code !== 0) return this.$message.error(res.message);
        this.$message.success("添加成功");
        //    关闭添加窗口
        this.addDialogVisible = false;
        this.getinforList();
      });
    },
    //删除用户
    async deleteEditDialog(id) {
      const confirmRessult = await this.$confirm(
        "此操作将永久删除, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);

      if (confirmRessult !== "confirm") {
        return this.$message.info("已经取消删除!");
      }
      const { data: res } = await this.$http.post("user/delete", {
        user_id: id,
      });
      if (res.code !== 0) {
        return this.$message.error("删除失败!");
      }
      console.log(res);
      this.$message.success("删除成功!");
      this.getinforList()
    },
    // 展示要修改的内容
    // async showEditDialog(id) {
    //   console.log(id);
    //   this.ids.user_id = id;
    //   this.editDialogVisible = true;
    //   const { data: res } = await this.$http.put("user/detail", this.ids);
    //   if (res.code !== 0) return this.$message.error("查询失败");
    //   this.editForm = res.data;
    // },
    editDialogClosed() {
      this.$refs["editFormRef"].resetFields();
    },
    editUser() {
      // 预验证
      this.$refs.editFormRef.validate(async (valid) => {
        if (!valid) return;
        // 调用后台数据创建用户
        if (this.ids.password != this.ids.duplicatePassword) {
          this.$message.error("新密码和重复密码不相同！");
        }
        const { data: res } = await this.$http.post("user/updatePassword", { user_id: this.ids.user_id, password: this.ids.password });
        if (res.code !== 0) return this.$message.error(res.message);
        this.$message.success("修改密码成功");
        //    关闭添加窗口
        this.editDialogVisible = false;
        this.getinforList();
      });
    },
    // 改变用户状态
    async changeState(userinfo) {
      console.log(userinfo);
      this.usestatuss.id = userinfo.id;
      this.usestatuss.use_status = userinfo.use_status;
      const { data: res } = await this.$http.put(
        "user/modifyUseStatus",
        this.usestatuss
      );
      if (res.code !== 0) return this.$message.error(res.message);
      this.$message.success("改变用户状态成功");
    },
    //改变角色合集
    async changeRole(item) {
      console.log(item);
      this.usestatustwo.id = item.id;
      this.usestatustwo.role_ids = item.roles_ids;
      const { data: res } = await this.$http.put(
        "admin/modifyRole",
        this.usestatustwo
      );
      if (res.code !== 0) return this.$message.error(res.message);
      this.$message.success("改变用户角色成功");
    },
  },
};
</script>

<style lang="less" scoped></style>