<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>素材列表</el-breadcrumb-item>
      <el-breadcrumb-item> </el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="20">
        <el-col :span="7">
          <el-input clearable placeholder=" 请输入内容" v-model="queryinfo.order_no">
            <el-button slot="append" icon="el-icon-search" @click="searchChange()"></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="addDialogVisible = true">添加一级菜单</el-button>
        </el-col>
      </el-row>
      <el-table :data="userlist" border stripe>
        <el-table-column label="标题" prop="title"></el-table-column>
        <el-table-column label="网站链接" prop="url"></el-table-column>
        <el-table-column label="图片">
          <template slot-scope="scope">
            <el-image :src="scope.row.image_url" fit="fill"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)"></el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini"
              @click="deleteEditDialog(scope.row.id)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分页区域-->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryinfo.page" :page-sizes="[5, 10, 15, 20]" :page-size="queryinfo.list_rows"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </el-card>
    <el-dialog title="创建轮播图" :visible.sync="addDialogVisible" width="30%" @closed="addDialogClosed">
      <el-form :model="addForm" :rules="addRules" ref="addFormRef" label-width="70px">
        <el-form-item label="层级" prop="domain_name">
          <el-input v-model="addForm.sort" disabled></el-input>
        </el-form-item>
        <el-form-item label="标题" prop="domain_name">
          <el-input v-model="addForm.title"></el-input>
        </el-form-item>
        <el-form-item label="网址链接" prop="domain_url">
          <el-input v-model="addForm.url"></el-input>
        </el-form-item>
        <el-form-item label="图片">
          <el-image :src="addForm.image_url" style="background-color: antiquewhite" v-if="addForm.image_url"></el-image>
          <el-upload :action="uploadUrl" :headers="headerObj" :on-remove="handleRemove" :on-success="handleSuccess"
            :show-file-list="false" class="upload-demo">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUser">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改文章信息 -->
    <el-dialog title="查看素材信息" :visible.sync="editDialogVisible" width="50%" @closed="editDialogClosed">
      <el-form :model="editForm" :rules="editRules" ref="editFormRef" label-width="70px">
        <el-form-item label="标题" prop="title">
          <el-input v-model="editForm.title"></el-input>
        </el-form-item>
        <el-form-item label="网址链接" prop="domain_url">
          <el-input v-model="editForm.url"></el-input>
        </el-form-item>
        <el-form-item label="图片" prop="image_url">
          <el-image :src="editForm.image_url" style="background-color: antiquewhite"></el-image>
          <el-upload :action="uploadUrl" :headers="headerObj" :on-remove="handleRemove" :on-success="handleSucces"
            :show-file-list="false" class="upload-demo">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editUser">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 获取文章信息列表
      queryinfo: {
        order_no: "",
        page: 1,
        list_rows: 5,
      },
      userlist: [],
      total: 0,
      //控制创建文章对话框展示与隐藏
      addDialogVisible: false,
      addForm: {
        title: "",
        image_url: "",
        sort: 1,
        url: "",
        // 上传的图片临时路径
        pics: [],
      },
      addRules: {
        title: [
          {
            required: true,
            message: "请输入文章标题",
            trigger: "blur",
          },
        ],
        editor: [
          {
            required: true,
            message: "请输入作者名称",
            trigger: "blur",
          },
        ],
        body: [
          {
            required: true,
            message: "请输入文本内容",
            trigger: "blur",
          },
        ],
      },
      editDialogVisible: false,
      options: [
        {
          value: "2",
          label: "审核通过",
        },
        {
          value: "3",
          label: "驳回",
        },
      ],
      maun: {
        id: 0,
      },
      editForm: {},
      editRules: {
        title: [
          {
            required: true,
            message: "请输入文章标题",
            trigger: "blur",
          },
        ],
        editor: [
          {
            required: true,
            message: "请输入作者名称",
            trigger: "blur",
          },
        ],
        source: [
          {
            required: true,
            message: "请输入文章来源",
            trigger: "blur",
          },
        ],
        body: [
          {
            required: true,
            message: "请输入文本内容",
            trigger: "blur",
          },
        ],
      },
      // 上传图片的url
      uploadUrl: "https://api.hxltw.cn/admin/AliCloudOss/image",
      // 图片上传组件的headers请求头对象
      headerObj: {
        Authorization: window.sessionStorage.getItem("token"),
      },
      previewPath: "",
      previewVisible: false,
    };
  },
  created() {
    this.getinforList();
  },
  methods: {
    // 获取所有菜单
    async getinforList() {
      const { data: res } = await this.$http.post("banner/index", {
        params: this.queryinfo,
        page: this.queryinfo.page,
        list_rows: this.queryinfo.list_rows,
      });

      if (res.code !== 0) return this.$message.err("获取素材列表失败!");
      this.userlist = res.data.data;
      this.total = res.data.total;
    },
    // 监听handleSizeChange方法
    handleSizeChange(newsize) {
      this.queryinfo.list_rows = newsize;
      this.getinforList();
    },
    // 监听handleCurrentChange方法
    handleCurrentChange(newpage) {
      this.queryinfo.page = newpage;
      this.getinforList();
    },
    // 搜索方法
    searchChange() {
      this.queryinfo.page = 1;
      this.getinforList();
    },
    // 添加用户关闭
    addDialogClosed() {
      this.$refs["addFormRef"].resetFields();
    },
    //删除用户
    async deleteEditDialog(id) {
      console.log(id);
      this.maun.id = id;
      const confirmRessult = await this.$confirm(
        "此操作将永久删除, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);

      if (confirmRessult !== "confirm") {
        return this.$message.info("已经取消删除!");
      }
      const { data: res } = await this.$http.post("banner/delete", this.maun);
      if (res.code !== 0) {
        return this.$message.error("删除失败!");
      }
      console.log(res);
      this.$message.success("删除成功!");
      this.getinforList();
    },
    // 查询要修改的领域
    async showEditDialog(ids) {
      console.log(ids);
      this.maun.id = ids;
      this.editDialogVisible = true;
      const { data: res } = await this.$http.post("banner/detail", this.maun);
      if (res.code !== 0) return this.$message.error("查询失败");
      this.editForm = res.data;
      console.log(this.editForm);
    },
    editDialogClosed() {
      this.$refs["editFormRef"].resetFields();
    },
    //编辑领域
    editUser() {
      // 预验证
      this.$refs.editFormRef.validate(async (valid) => {
        if (!valid) return;
        // 调用后台数据创建用户
        const { data: res } = await this.$http.put(
          "banner/edit",
          this.editForm
        );
        if (res.code !== 0) return this.$message.error(res.meta.msg);
        this.$message.success("编辑用户成功");
        //    关闭添加窗口
        this.editDialogVisible = false;
        this.getinforList();
      });
    },
    /**
     * 处理移除图片的操作
     */
    handleRemove(file) {
      console.log(file, "fileList");

      // 1.获取将要删除的图片的临时路径
      const filePath = file.data.file_path;
      // 2.从image数组中, 找到这个图片对应的索引值
      const index = this.addForm.image.findIndex((item) => {
        return item.pic === filePath;
      });

      // 3.调用数组的splice方法, 把图片信息对象, 从image数组中移除
      this.addForm.image.splice(index, 1);
    },
    /**
     * 监听图片上传的成功的事件
     */
    handleSucces(response) {
      console.log(response);
      // 1.拼接得到一个图片信息对象
      const picInfo = {
        image_urls: response.data.file_path,
      };
      console.log(this.editForm.image_url);
      this.editForm.image_urls = [];
      console.log(12, picInfo);
      // 2.将图片信息对象, push到 image数组中
      this.editForm.image_urls.push(picInfo);
      console.log(this.editForm.image_urls[0]);

      this.editForm.image_url = this.editForm.image_urls[0].image_urls;
      console.log(this.editForm.image_url);
    },
    handleSuccess(response) {
      console.log(response);
      // 1.拼接得到一个图片信息对象
      const picInfo = {
        image_urls: response.data.file_path,
      };
      console.log(this.addForm.image_url);
      this.addForm.image_urls = [];
      console.log(12, picInfo);
      // 2.将图片信息对象, push到 image数组中
      this.addForm.image_urls.push(picInfo);
      console.log(this.addForm.image_urls[0]);

      this.addForm.image_url = this.addForm.image_urls[0].image_urls;
      console.log(this.addForm.image_url);
    },
    //添加
    addUser() {
      // 预验证
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) return;
        // 调用后台数据创建用户
        const { data: res } = await this.$http.post("banner/add", this.addForm);
        if (res.code !== 0) return this.$message.error(res.message);
        this.$message.success("添加用户成功");
        //    关闭添加窗口
        this.addDialogVisible = false;
        this.addDialogVisibles = false;
        this.addForm.image_url = "";
        this.getinforList();
      });
    },
  },
};
</script>

<style lang="less" scoped></style>